import React from 'react';
import Layout from '../components/layout';

import styles from './error.module.scss';
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <div className={styles.errorPage}>
      <div className={styles.header}>The page you are looking for cannot be found</div>
      <div className={styles.description}>Learn more about Blueprint <Link to='/'>here</Link></div>
    </div>
  </Layout>
)

export default NotFoundPage
